.container-contactUs h2 {
  font-size: 4rem;
  font-weight: bold;
}

.container-contactUs h3 {
  font-weight: bold;
}

.container-contactUs img {
  height: 4rem;
}

.container-contactUs {
  height: auto;
}
@media screen and (max-width: 992px) {
  .container-contactUs {
    height: auto;
    padding-bottom: 1.5rem;
  }
  .container-contactUs .col-lg-4 {
    padding: 1rem !important;
  }
  .container-contactUs .title {
    padding-left: 1rem;
  }
  .container-contactUs div:first-child {
    padding-bottom: 1rem !important;
  }
}
