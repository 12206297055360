form {
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  font-size: 16px;

  input {
    width: 100%;
    height: 3rem;
    padding: 7px;
    outline: none;
    border: 4px solid #ffffff;
    background-color: #000000;
    color: #ffffff;

    &:focus {
      border: 4px solid #ffcd02;
    }
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 250px;
    min-height: 250px;
    padding: 7px;
    outline: none;
    border: 4px solid #ffffff;
    color: #ffffff;
    background-color: #000000;

    &:focus {
      border: 4px solid #ffcd02;
    }
  }

  label {
    margin-top: 1rem;
  }

  input[type="submit"] {
    margin-top: 2rem;
    cursor: pointer;
    border: 3px solid #ffcd02;
    color: #ffcd02;
    font-weight: bold;
    height: 3.5rem;
    border-radius: 20px 0px 20px / 20px 20px;
    padding: 11px 20px;
    width: 15rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
  input[type="submit"]:hover {
    background-color: #ffcd02;
    color: #000000;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
}

.link-mail {
  color: #ffffff;
  font-weight: normal;
  text-decoration: underline;
}

.wrapper-checkbox a {
  text-decoration: underline;
  color: #ffffff;
}
