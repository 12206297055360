.container-aboutus h2 {
  font-weight: bold;
  font-size: 3em;
}

.container-aboutus p {
  font-size: 1.5em;
  margin-bottom: 0rem;
}

.values-container p {
  font-size: 1.1em;
}

.linkedin-coll {
  color: #ffffff;
  text-decoration: underline;
}

.governance-container a,
.governance-container p {
  font-size: 1.1rem;
  font-weight: normal;
  margin-bottom: 3px;
}

.governance-container img {
  width: 13em;
}

@media screen and (max-width: 992px) {
  .values-container div,
  .values-container {
    padding: 0 !important;
  }
  .container-aboutus div:nth-child(2),
  .container-aboutus div:nth-child(3),
  .container-aboutus div:nth-child(4),
  .container-aboutus div:nth-child(5) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .governance-container {
    padding-top: 2rem !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
    display: flex;
    flex-wrap: wrap;
  }
  .governance-container .px-5.mx-5 {
    padding-left: 0.3rem !important;
    padding-right: 0.5rem !important;
    margin: 0 !important;
    padding-bottom: 2rem;
    flex: 50%;
  }
  .governance-container a,
  .governance-container p {
    font-size: 0.8rem;
    font-weight: normal;
    margin-bottom: 3px;
  }
  .governance-container img {
    width: 8rem;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .governance-container .px-5.mx-5 {
    flex: 30% 1;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .governance-container .px-5.mx-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
