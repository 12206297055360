a {
  color: #000000;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
  z-index: 100000;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  margin-right: 5rem;
  margin-left: 5rem;
}

.nav__logo {
  color: var(--first-color);
  transition: color 0.4s;
  font-size: var(--h2-font-size);
  font-family: var(--second-font);
}

.nav__toggle,
.nav__close {
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}

.nav__cta {
  background-color: var(--first-color);
  color: var(--title-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem 1.5rem;
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  list-style-type: none;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}

.nav__list__web {
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
  align-items: center;
  list-style-type: none;
  padding: 0;
  padding-top: 1.5rem;
}

.nav__link.active {
  text-decoration: underline;
  text-decoration-color: #ffcd02;
  text-underline-offset: 6px;
  text-decoration-thickness: 3px;
}
@media screen and (max-width: 992px) {
  .nav {
    margin: 1rem;
  }
  .nav__list li a {
    font-size: 25px;
  }
  .nav__toggle svg {
    font-size: 45px;
    margin-top: 1rem;
  }
  .nav__close svg {
    font-size: 45px;
    margin-left: 1rem;
    margin-top: 1.5rem;
  }
  .nav__logo img {
    width: 230px;
    height: auto;
  }
  .nav__list {
    padding-left: 0;
  }
}
