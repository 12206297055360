h1 {
  font-size: 5em;
  font-weight: bold;
}
.name {
  font-weight: bold;
}
.subtitle {
  font-size: 2em;
}
.button-disc {
  border: 3px solid #ffcd02;
  color: #ffcd02;
  border-radius: 20px 0px 20px / 20px 20px;
  padding: 11px 20px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.button-disc:hover {
  background-color: #ffcd02;
  color: #000000;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.icashly-wrapper-img img {
  border: 4px solid #ffffff;
  border-radius: 20px 0px 20px / 20px 20px;
}

.icashly-logo-wrapper p {
  font-size: 2.1em;
}

.button-products {
  border: 3px solid #ffffff;
  color: #ffffff;
  border-radius: 20px 0px 20px / 20px 20px;
  padding: 11px 20px;
  font-weight: bold;
}

@media screen and (max-width: 992px) {
  .img-teams img {
    height: 350px !important;
  }
  h1 {
    font-size: calc(2.375rem + 1.5vw);
  }
  .subtitle {
    font-size: 1.5em;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .icashly-home-wrapper div {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    margin: 0 !important;
  }
  .icashly-logo-wrapper img {
    width: 100%;
  }
  .icashly-logo-wrapper p {
    text-align: center;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .img-teams img {
    height: 640px !important;
  }
}
