input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 1.6em;
  height: 1.6em;
  border-radius: 0.15em;
  margin-right: 0.5em;
  border: 0.15em solid #ffffff;
  outline: none;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-color: #ffcd02;
  position: relative;
}

input[type="checkbox"]:disabled {
  border-color: #ffffff;
  background-color: #000000;
}

input[type="checkbox"]:disabled + span {
  color: #ffffff;
}

input[type="checkbox"]:focus {
  border: 0.15em solid #ffffff;
}
