.container-services h2 {
  font-weight: bold;
  font-size: 3em;
}

.container-services p {
  font-size: 1.5em;
}

.container-wrapper-info img.services-img {
  width: 100%;
}

.container-wrapper-info img,
.container-how-we-do-it img.icon {
  width: 4rem;
}

.wrapper-info-services p {
  padding: 0px !important;
}

.container-wrapper-info .services-wrapper,
.container-how-we-do-it .services-wrapper {
  border: 3px solid #ffffff;
  border-radius: 32px 0px 32px / 35px 35px;
}

.container-how-we-do-it .services-wrapper {
  height: 100%;
}

.services-wrapper p {
  font-size: 1rem;
}

.services-wrapper p,
h3 {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.services-wrapper img.icon {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 0.5rem !important;
  margin-top: 1.5rem;
}

.services-wrapper h3 {
  font-weight: bold;
}

.bullet {
  h1 {
    display: list-item;
    margin-left: 1em;
  }
}

@media screen and (max-width: 992px) {
  .container-wrapper-info .col-lg-6 {
    padding: 3rem 0px 0 0px !important;
  }
  .container-wrapper-info .services-wrapper,
  .container-how-we-do-it .services-wrapper {
    border-radius: 17px 0px 17px / 15px 30px;
  }
  .container-how-we-do-it .d-lg-flex .col-lg-4 {
    padding: 3rem 0px 0 0px !important;
  }
  .container-how-we-do-it p:nth-child(1) {
    margin: 0 !important;
  }
  .container-how-we-do-it .d-lg-flex {
    margin-top: 1rem !important;
  }
  .container-services .title,
  .wrapper-info-services {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .wrapper-buttons {
    display: none !important;
  }
}
