.app-wrapper {
  background-color: #000000;
  padding-top: 7rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: IBM Plex Mono;
  color: #ffffff;
}

p,
div,
a {
  font-family: IBM Plex Sans;
  color: #ffffff;
}

.ibm-plex-mono-thin {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-mono-extralight {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-mono-light {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-mono-regular {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-mono-medium {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-mono-semibold {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-mono-bold {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-mono-thin-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 100;
  font-style: italic;
}

.ibm-plex-mono-extralight-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 200;
  font-style: italic;
}

.ibm-plex-mono-light-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 300;
  font-style: italic;
}

.ibm-plex-mono-regular-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.ibm-plex-mono-medium-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  font-style: italic;
}

.ibm-plex-mono-semibold-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 600;
  font-style: italic;
}

.ibm-plex-mono-bold-italic {
  font-family: "IBM Plex Mono", monospace;
  font-weight: 700;
  font-style: italic;
}

.ibm-plex-sans-thin {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.ibm-plex-sans-extralight {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.ibm-plex-sans-light {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.ibm-plex-sans-regular {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ibm-plex-sans-medium {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.ibm-plex-sans-semibold {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.ibm-plex-sans-bold {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.ibm-plex-sans-thin-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.ibm-plex-sans-extralight-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.ibm-plex-sans-light-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.ibm-plex-sans-regular-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.ibm-plex-sans-medium-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.ibm-plex-sans-semibold-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.ibm-plex-sans-bold-italic {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.picker-lang-andromeda * {
  color: #000000;
}

.picker-lang-andromeda button {
  background: none;
  color: #000000;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.picker-lang-andromeda button.active {
  color: #ffcd02;
}
.show-menu .picker-lang-andromeda button,
.show-menu .picker-lang-andromeda span {
  color: #ffffff;
}

.show-menu .picker-lang-andromeda button.active {
  color: #ffcd02;
}

@media screen and (max-width: 992px) {
  video {
    width: 100%;
    height: 400px !important;
  }
  .picker-lang-andromeda {
    font-size: 25px;
  }
  .picker-lang-andromeda button,
  .picker-lang-andromeda span {
    color: #ffffff;
  }
}

@media screen and (max-width: 1150px) {
  .iub__us-widget,
  .iub__us-widget__link {
    justify-content: center !important;
  }
  .iub__us-widget {
    display: block;
  }
  .iub__us-widget__link--privacy-choices::after {
    margin-right: 0.8em !important;
  }
}

@media screen and (min-width: 992px) {
  .iub__us-widget__wrapper {
    justify-content: center;
  }
}
