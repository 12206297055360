.footer-container {
  border-bottom: 2px solid #000000;
}

.footer-container h1,
.footer-container h2,
.footer-container h3,
.footer-container h4,
.footer-container h5,
.footer-container p,
.footer-container a,
.row-footer-legal a,
.row-footer-legal p {
  color: #000000;
}

.row-footer-legal p.title {
  font-weight: bold;
  margin: 0 !important;
}

.row-footer-legal .nav-link {
  display: inline;
  position: relative;
}

.footer-container a {
  font-weight: normal;
}

.footer-container a:hover,
.row-footer-legal a:hover {
  color: #000000;
  text-decoration: underline;
}

.footer-ico-wrapper {
  position: relative;
  height: 100%;
}
.footer-ico-wrapper img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.title-text-footer {
  font-weight: bold;
}
.font-social {
  font-size: 1.3em;
}

.row-footer-legal .nav-link:focus {
  color: #000000;
}
.pec-footer a {
  text-decoration: underline;
}
.font-social:hover {
  color: #ffcd02;
}

@media screen and (max-width: 992px) {
  .row-footer-legal:last-child .text-end {
    text-align: center !important;
    padding-bottom: 1rem;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .footer-container {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-container div {
    flex: 50%;
    margin-bottom: 1rem;
  }
  .footer-container .pittogramma-wrapper-footer {
    width: 100%;
    text-align: right;
  }
  .footer-ico-wrapper img {
    position: relative !important;
  }
  .iub__us-widget__wrapper {
    width: 100%;
  }
}
