.wrapper-icashly-desc p {
  font-size: 1.3em;
}

.button-products {
  border: 3px solid #ffffff;
  color: #ffffff;
  border-radius: 20px 0px 20px / 20px 20px;
  padding: 11px 20px;
  font-weight: bold;
}
@media screen and (max-width: 992px) {
  .products-wrapper img.banner {
    height: 250px;
    margin-top: 0 !important;
  }
  .wrapper-icashly-desc {
    text-align: center;
  }
  .wrapper-icashly-desc p {
    text-align: start;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .wrapper-buttons {
    display: none !important;
  }
}
